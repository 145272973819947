import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import statusToString from "../../common/utils/status-to-string";
import { sec } from "../../security";
import { addNotification } from "../notifications/notificationsSlice";
import { setAddNewJobValues } from "./jobSlice";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiJobSlice = createApi({
  reducerPath: "apiJob",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    /* TARGETS API */
    getSharedTargetListResponse: builder.query({
      query: ({ jobId }) => `/api/v1/jobs/${jobId}/shared_target_lists_response`
    }),
    getPartnersToShare: builder.query({
      query: () => `/api/v1/my_employees/partners`
    }),
    getTargets: builder.query({
      query: ({ jobId, filters = "" }) =>
        `/api/v1/jobs/${jobId}/targets?${filters}`
    }),
    getTargetsNotFitForJob: builder.query({
      query: ({ jobId }) => `/api/v1/jobs/${jobId}/targets/removed`
    }),
    getTargetsNotFitForFirm: builder.query({
      query: ({ employerId, page = 0, pageSize = 25 }) =>
        `/employers/${employerId}/targets_no_to_firm?page=${page}&per=${pageSize}`
    }),
    updateTarget: builder.mutation({
      query: ({ jobId, targetId, status, reason, reasonText }) => ({
        url: `/jobs/${jobId}/targets/${targetId}/status`,
        method: "PATCH",
        body: {
          target: {
            status,
            reason,
            reasonText
          }
        }
      }),
      async onQueryStarted(
        {
          jobId,
          targetId,
          status,
          filters = "",
          hasAdditionalAttorneys = false,
          noUpdateCache = false
        },
        { dispatch, queryFulfilled }
      ) {
        if (hasAdditionalAttorneys || noUpdateCache) {
          return;
        }
        const patchResult = dispatch(
          apiJobSlice.util.updateQueryData(
            "getTargets",
            { jobId, filters },
            (draft) => {
              const target = draft?.targets.find(
                (item: { attorney_id: any }) => item.attorney_id === targetId
              );
              // Reference: 1 = "yes_to_job"
              if (target && status === 1) {
                target.status = statusToString(status);
              }
              if (target && !target.attorney?.manually_added && status !== 1) {
                target.status = statusToString(status);
              } else if (target?.attorney?.manually_added && status !== 1) {
                const updatedTargets = draft?.targets.filter(
                  (item: { attorney_id: any }) => item.attorney_id !== targetId
                );
                if (updatedTargets) {
                  const newDraft = { ...draft, targets: updatedTargets };
                  Object.assign(draft, newDraft);
                }
              }
            }
          )
        );
        try {
          dispatch(
            addNotification({
              message: "Candidate updated successfully",
              type: "success"
            })
          );
          await queryFulfilled;
        } catch {
          dispatch(
            addNotification({
              message: "Failed to update candidate",
              type: "danger"
            })
          );
          patchResult.undo();
        }
      }
    }),
    deleteTargetNotFitForJob: builder.mutation({
      query: ({ jobId, targetId, status, reason, reasonText }) => ({
        url: `/jobs/${jobId}/targets/${targetId}/status`,
        method: "PATCH",
        body: {
          target: {
            status,
            reason,
            reasonText
          }
        }
      }),
      async onQueryStarted({ jobId, targetId }, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          apiJobSlice.util.updateQueryData(
            "getTargetsNotFitForJob",
            { jobId },
            (draft) => {
              const updatedTargets = draft?.targets.filter(
                (item: { attorney_id: any }) => item.attorney_id !== targetId
              );
              if (updatedTargets) {
                const newDraft = { ...draft, targets: updatedTargets };
                Object.assign(draft, newDraft);
              }
            }
          )
        );
        try {
          dispatch(
            addNotification({
              message: "Candidate deleted successfully",
              type: "success"
            })
          );
          await queryFulfilled;
        } catch {
          dispatch(
            addNotification({
              message: "Failed to delete candidate",
              type: "danger"
            })
          );
          deleteResult.undo();
        }
      }
    }),
    deleteTargetNotFitForFirm: builder.mutation({
      query: ({ jobId, targetId, status, reason, reasonText }) => ({
        url: `/jobs/${jobId}/targets/${targetId}/status`,
        method: "PATCH",
        body: {
          target: {
            status,
            reason,
            reasonText
          }
        }
      }),
      async onQueryStarted(
        { employerId, targetId },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          apiJobSlice.util.updateQueryData(
            "getTargetsNotFitForFirm",
            { employerId },
            (draft) => {
              const updatedTargets = draft?.targets.filter(
                (item: { attorney: any }) => item.attorney?.id !== targetId
              );

              if (updatedTargets) {
                const newDraft = { ...draft, targets: updatedTargets };
                Object.assign(draft, newDraft);
              }
            }
          )
        );
        try {
          dispatch(
            addNotification({
              message: "Candidate deleted successfully",
              type: "success"
            })
          );
          await queryFulfilled;
        } catch {
          dispatch(
            addNotification({
              message: "Failed to delete candidate",
              type: "danger"
            })
          );
          deleteResult.undo();
        }
      }
    }),
    shareTargetList: builder.mutation({
      query: ({ jobId, emails }) => ({
        url: `/api/v1/jobs/${jobId}/shared_target_lists`,
        method: "POST",
        body: {
          emails
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Shared candidates list successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to share candidates list",
              type: "danger"
            })
          );
        }
      }
    }),
    /* JOB NOTES API */
    getJobNotes: builder.query({
      query: ({ jobId }) => `/jobs/${jobId}/job_notes`
    }),
    getJobNote: builder.query({
      query: ({ jobId, noteId }) => `/jobs/${jobId}/job_notes/${noteId}`
    }),
    postJobNote: builder.mutation({
      query: ({ jobId, text, pinned }) => ({
        url: `/jobs/${jobId}/job_notes`,
        method: "POST",
        body: {
          job_note: {
            text,
            pinned
          }
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Note added successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to add note",
              type: "danger"
            })
          );
        }
      }
    }),
    patchJobNote: builder.mutation({
      query: ({ jobId, noteId, text, pinned }) => ({
        url: `/jobs/${jobId}/job_notes/${noteId}`,
        method: "PATCH",
        body: {
          job_note: {
            text,
            pinned
          }
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Note updated successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to update note",
              type: "danger"
            })
          );
        }
      }
    }),
    deleteJobNote: builder.mutation({
      query: ({ jobId, noteId }) => ({
        url: `/jobs/${jobId}/job_notes/${noteId}`,
        method: "DELETE"
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Note deleted successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to delete note",
              type: "danger"
            })
          );
        }
      }
    }),
    /* CANDIDATES NOTES API */
    getTargetNotes: builder.query({
      query: ({ targetId }) => `/targets/${targetId}/target_notes`
    }),
    getTargetNote: builder.query({
      query: ({ targetId, noteId }) =>
        `/targets/${targetId}/target_notes/${noteId}`
    }),
    postTargetNote: builder.mutation({
      query: ({ targetId, text, pinned }) => ({
        url: `/targets/${targetId}/target_notes`,
        method: "POST",
        body: {
          target_note: {
            text,
            pinned
          }
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Note added successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to add note",
              type: "danger"
            })
          );
        }
      }
    }),
    patchTargetNote: builder.mutation({
      query: ({ targetId, noteId, text, pinned }) => ({
        url: `/targets/${targetId}/target_notes/${noteId}`,
        method: "PATCH",
        body: {
          target_note: {
            text,
            pinned
          }
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Note updated successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to update note",
              type: "danger"
            })
          );
        }
      }
    }),
    deleteTargetNote: builder.mutation({
      query: ({ targetId, noteId }) => ({
        url: `/targets/${targetId}/target_notes/${noteId}`,
        method: "DELETE"
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Note deleted successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to delete note",
              type: "danger"
            })
          );
        }
      }
    }),
    /* SHARED CONNECTIONS */
    getSharedConnections: builder.query({
      query: ({ attorneyId }) =>
        `/api/v1/attorneys/${attorneyId}/shared_connections`
    }),
    /* GET JOB */
    getJob: builder.query({
      query: ({ jobId }) => `/jobs/${jobId}`,
      transformResponse: (response: any) => response.job
    }),
    /* ADD JOB */
    postFetchPostingData: builder.mutation({
      query: ({ title, description }) => ({
        url: `/api/v1/jobs/fetch_posting_data`,
        method: "POST",
        body: {
          title,
          displayed_description: description
        }
      }),
      transformResponse: (response: any) => response,
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setAddNewJobValues({
              ...data,
              title: arg.title,
              displayed_description: arg.description
            })
          );
          dispatch(
            addNotification({
              message: "Posting data fetched successfully",
              type: "success"
            })
          );
        } catch (error) {
          dispatch(
            addNotification({
              message: "Failed to fetch posting data",
              type: "danger"
            })
          );
        }
      }
    }),
    postNewJob: builder.mutation({
      query: (job) => ({
        url: "/api/v1/jobs",
        method: "POST",
        body: { job }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Job added successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to add job",
              type: "danger"
            })
          );
        }
      }
    }),
    /* EDIT JOB */
    patchJob: builder.mutation({
      query: ({ jobId, job }) => ({
        url: `/api/v1/jobs/${jobId}`,
        method: "PATCH",
        body: { job }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Job updated successfully",
              type: "success"
            })
          );
        } catch {
          dispatch(
            addNotification({
              message: "Failed to update job",
              type: "danger"
            })
          );
        }
      }
    }),
    /* GET MARKET INTEL */
    getJobMarketIntel: builder.query({
      query: ({ jobId }) => `/jobs/${jobId}/market_intel`,
      transformResponse: (response: any) => response.locations
    })
  }),
  refetchOnMountOrArgChange: true
});

// Export hooks for usage in functional components
export const {
  useGetSharedTargetListResponseQuery,
  useGetPartnersToShareQuery,
  useGetTargetsQuery,
  useGetTargetsNotFitForJobQuery,
  useGetTargetsNotFitForFirmQuery,
  useUpdateTargetMutation,
  useDeleteTargetNotFitForJobMutation,
  useDeleteTargetNotFitForFirmMutation,
  useShareTargetListMutation,
  useGetJobNotesQuery,
  useGetJobNoteQuery,
  usePostJobNoteMutation,
  usePatchJobNoteMutation,
  useDeleteJobNoteMutation,
  useGetTargetNotesQuery,
  useGetTargetNoteQuery,
  usePostTargetNoteMutation,
  usePatchTargetNoteMutation,
  useDeleteTargetNoteMutation,
  useGetSharedConnectionsQuery,
  useGetJobQuery,
  usePostFetchPostingDataMutation,
  usePostNewJobMutation,
  usePatchJobMutation,
  useGetJobMarketIntelQuery
} = apiJobSlice;
